import React, { useState } from 'react'
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  StorageApi
} from '../MainCode/MainImport'
import { useSession, getSession,signOut } from "next-auth/react"
// import Image from 'next/image';
import { useEffect } from 'react';
import { Image, message } from 'antd';



function SideBar(props) {
  const router = useRouter();
  const {colorText,statusMode,bgColor} = props
  const [openmenu, setOpenmenu] = useState([])
  const [menuparent, setMenuParent] = useState([])
  const [menusubparent, setMenuSubParent] = useState([])
  const [loading, setLoading] = useState(false)
  const { data: session, status } = useSession()
  const link_akses = router.asPath.split('/')
  const link_aksess = router.asPath
  const link_aktif = link_akses[link_akses.length - 1]
  
  const menuaktif = "items-center px-4 py-1 text-base text-white transition duration-500 focus:shadow-outline ease-in-out transform shadow-inner bg-gradient-to-b from-[#1E42B0] to-[#07B5D4]";
  const menunonaktif = "items-center  px-4 py-1  rounded-l-2xl ml-2 text-base transition duration-500 focus:shadow-outline transform hover:shadow-inner"
  useEffect(() => {
    cekSesi()
    MenuParent();
    MenuSubParent();
  }, [loading])

  const cekSesi = async () => {
    let sesi = await getSession();
    if (!sesi) {
      tendang('Sesi anda telah berakhir')
    } else if (sesi) {
      Promise.resolve(StorageApi.getData(`sso_check?kode_login=${sesi.token_login}`))
      .then(value => {
        if (value.data.data[0].kode_login !== sesi.token_login) {
          tendang('Sesi anda telah berakhir')
        }
      }).catch(error => {
        console.log(error)
        tendang('Sesi anda telah berakhir')
      })
    }
  }

  const tendang = async (pesan) => {
    localStorage.clear()
    await signOut({ redirect: false })
    message.info(pesan)
    router.push('/');
  }

  const subMenuOnMouseEnter = (subMenuId) => {
    setOpenmenu((prev) => {
      let arr = [...prev]
      arr[subMenuId] = true
      return arr
    })
  }

  const subMenuOnMouseDown = (subMenuId) => {
    setOpenmenu((prev) => {
      let arr = [...prev]
      arr[subMenuId] = false
      return arr
    })
  }

  const MenuParent = async () => {
    const sesi = await getSession()
    let arr = []
    if (sesi.kode_unit) { arr = [...arr, `kode_unit=${sesi.kode_unit}`] }
    if (sesi.kode_sub_unit) { arr = [...arr, `kode_sub_unit=${sesi.kode_sub_unit}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    Promise.resolve(StorageApi.getData(`sm_hrm/page_group` + param))
      .then(value => {
        const detail = value.data.data
        setMenuParent(detail)
      }).catch(error => {
        setMenuParent([])
      })
  }

  const MenuSubParent = async () => {
    const sesi = await getSession()
    let arr = []
    if (sesi.kode_unit) { arr = [...arr, `kode_unit=${sesi.kode_unit}`] }
    if (sesi.kode_sub_unit) { arr = [...arr, `kode_sub_unit=${sesi.kode_sub_unit}`] }
    let param = '';
    param = arr.length > 0 ? '&' + arr.join('&') : '';
    Promise.resolve(StorageApi.getData(`sm_hrm/page_group?level=2` + param))
      .then(value => {
        const detail = value.data.data
        setMenuSubParent(detail)
      }).catch(error => {
        setMenuSubParent([])
      })
  }

  const cekHalaman = (daftar_menu)=>{
      const hasil =  daftar_menu.filter((tes) => `/${tes.link.trim()}` == link_aksess)
      return hasil
  }


  return (
    <div>
      <nav className="flex-1">
        <ul className=''>
          {menuparent.map(item => {
            const page_count = item.link.split('/')
            const page = page_count[page_count.length - 1]
            const cekLink = cekHalaman(menuparent)
            var id = item.rowid
            // if (cekLink.length > 0 || link_aksess == session?.redirect.trim() || link_aksess == "/Hrm/profile" || link_aksess == "embed-file/embed-peta" ) {
              if (item.level == 1 && item.link != '') {
                return (
                  <div className={`py-1`} key={item.rowid}>
                    <li className={` ${(link_aktif == page.trim()) ? menuaktif : menunonaktif} ${statusMode ? 'hover:bg-[#24243E]' : 'hover:bg-[#EAEDF7]'}`}>
                      <Link href={`/${item.link}`}>
                        <a className={`${colorText} items-center justify-between px-1`}>
                            {/* <span className={` items-center ${colorText}`}> */}
                              {item.icon ? (<Image className={`${colorText != "text-[#000000]" ? "filter invert" : (link_aktif == page.trim()) ? " filter invert" : ""} mt-1`} src={item.icon} alt="image icon" width={20} preview={false}/>) : ''}
                              <span className={`${(link_aktif == page.trim()) ? " text-white" : colorText}  ml-2 -mt-4 text-[14px] font-semibold `} >{item.page}</span>
                            {/* </span> */}
                        </a>
                      </Link>
                    </li>
                  </div>
                )
              }

              if (item.link == "") {
                return (
                  <div className={`py-1`}  onMouseLeave={() => subMenuOnMouseDown(item.rowid)} onMouseEnter={() => subMenuOnMouseEnter(item.rowid)} key={item.rowid}>
                    <li className={` ${(link_aktif == page.trim()) ? menuaktif : menunonaktif} ${statusMode ? 'hover:bg-[#24243E]' : 'hover:bg-[#EAEDF7]'}`}>
                        <a className={`${colorText} items-center flex justify-between px-1`}>
                          <div className='items-center align-middle'>
                            {/* <span className={` items-center ${colorText}`}> */}
                              {item.icon ? (<Image className={`${colorText != "text-[#000000]" ? "filter invert" : (link_aktif == page.trim()) ? " filter invert" : ""} `} src={item.icon} alt="image icon" width={20} preview={false}/>) : ''}
                              <span className={`${(link_aktif == page.trim()) ? " text-white" : colorText} text-left  ml-2 -mt-3 text-[14px] font-semibold `} >{item.page}</span>
                            {/* </span> */}
                          </div>
                            <p className='text-right'>
                              <Image className={`${colorText != "text-[#000000]" ? "" :"filter invert"} mt-2`} src={`/icons/${openmenu ? 'down-arrowP' : 'up-arrowP'}.svg`} alt="image" width={20} height={20} preview={false}/>
                            </p>
                        </a>
                      <ul className={`${openmenu ? 'block' : 'block'}`}>
                        {openmenu[item.rowid] && menusubparent.map(item => {
                          const sub_count = item.link.split('/')
                          const subpage = sub_count[sub_count.length - 1]
                          if (id == item.parent_menu) {
                            return (
                              <div className={`py-1`} key={item.rowid}>
                                    <li className={` ${(link_aktif == subpage.trim()) ? `${menuaktif} rounded-l-2xl ` : menunonaktif} ${statusMode ? "hover:bg-slate-600" :"hover:bg-white"}`}>
                                      <Link href={`/${item.link}`} >
                                        <a className={`${colorText} items-center justify-between px-1`}>
                                           <span className={`${(link_aktif == subpage.trim()) ? " text-white" : colorText}  ml-2 -mt-4 text-[14px] font-semibold `} >{item.page}</span>
                                        </a>
                                      </Link>
                                    </li>
                              </div>
                            )
                          }
                        })}
                      </ul>
                    </li>
                  </div>
                )
              }
            // }else{
            //   router.push(session?.redirect)
            // }
          })}
        </ul>
      </nav>
    </div >
  )
}

export default SideBar