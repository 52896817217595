import React, { useEffect, useState } from 'react'
import { Table, Spin, message } from 'antd'
import Image from 'next/image';
import { InputCustom, SearchCustom } from '../MainCode/MainForm'
import StorageApi from '../APIConfig/MainApi';
import { Excel } from "antd-table-saveas-excel";

function DataTable(props) {
  let {columns,dataSource,loading,colorText,Tfooter,xScroll} = props
  const [paging, setPagnition] = useState(10);
  const [heading, setHeading] = useState([]);
  const [total_rows, setColumnsNow] = useState(0);
  const [loader_download, setLoaderDownload] = useState(false);
  const [download_file, setDownloadFile] = useState(false);
  const [list_download, setListDownload] = useState([]);
  const [data_download, setDataDownload] = useState([]);

  useEffect(() => {
    FilteringTable(columns,dataSource)   
  }, [columns])

  const FilteringTable = (field=[], data=[]) => {
    let myfield =[
      {title: 'No',dataIndex: 'no',width: '5%',align: 'center',sorter: (a, b) => a.no - b.no}
    ]
    for (let i = 0; i < field.length; i++) {
      if (field[i].filterSearch == undefined) {
        if (field[i].dataIndex == 'action') {
          myfield.push(
            { title: 'Action', 
              dataIndex: 'action',  
              align: 'center', 
              fixed: "right", 
              width : '13%'
            }
          )          
        }else{
          myfield.push(field[i])
        }
      }else{
        const indexData = field[i].dataIndex;
        const filtering = data.filter(
          (element, index) => (
            data.findIndex((obj) => obj[indexData] === element[indexData]) === index
          )
        );
        myfield.push(
          {
            title : field[i].title,
            dataIndex:field[i].dataIndex,
            filters: filtering.map(item => {
              return ({ text: item[indexData], value: item[indexData] })
            }),
            align : field[i].align != undefined ? field[i].align : 'left',
            filterSearch: true,
            onFilter: (filters, record) => record[indexData] && record[indexData].indexOf(filters) === 0,
          })
      }
    }
    setHeading(myfield)
  }


  const DataDownload = (link) => {
    setLoaderDownload(true)
    Promise.resolve(StorageApi.getData(link))
      .then(value => {
        const label = Object.keys(value.data.data[0]);
        const data = value.data.data;
        var title_download = [
          { title: "No", dataIndex: "no" }
        ]
        var data_download = []
        var data_real = []
        let judul = {}
        let data_mentah = []
        let data_array_1 = {}
        let number = []
        for (let index = 0; index < label.length; index++) {
          if (label[index].slice(-3) != "_id" && label[index] != "rowid") {
            title_download.push(
              { title: label[index], dataIndex: label[index] },
            )
          }
        }
        for (let index = 0; index < data.length; index++) {
          judul = Object.keys(data[index])
          data_mentah.push(
            Object.values(data[index])
          )
        }

        for (let i = 0; i < data_mentah.length; i++) {
          number.push({ no: i + 1 })
          for (let a = 0; a < data_mentah[i].length; a++) {
            var m = judul[a]
            if (m.slice(-3) != "_id" && m != "rowid") {
              data_array_1 = { ...data_array_1, [m]: data_mentah[i][a] }
            }
          }
          data_real.push(data_array_1)
          data_download.push({ ...data_real[i], "no": number[i].no });
        }
        setListDownload(title_download)
        setDataDownload(data_download)
        setLoaderDownload(false)
      }).catch(error => {
        setListDownload([])
        setDataDownload([])
        setLoaderDownload(false)
      })
  }

  const DownloadExcel = async (link) => {
    DataDownload(link)
    const excel = new Excel();
    excel
      .addSheet(`${props.filename}`)
      .addColumns(list_download)
      .addDataSource(data_download, {
        str2Percent: true
      })
    if (excel.currentRow != total_rows) {
      excel.saveAs(`${props.filename}.xlsx`)
      setDownloadFile(false)
      setColumnsNow(excel.currentRow)
    } else {
      message.success("Data berhasil diperbaharui")
      setColumnsNow(excel.currentRow)
      setDownloadFile(true)
    }
  };

  return (
    <>
      <div className='grid grid-cols-2'>
        <div className="justify-start items-center">
          <InputCustom type="number" width="w-[15%]" onChange={e => setPagnition(e.target.value)} /> <span className={`${colorText} text-[12px] font-light`}><b></b><b>{paging} rows/Page</b> </span>
        </div>
      {props.download ? (<>
        <div className='float-right mb-6'>
          <div className="flex items-center justify-end">
            {props.import ? (<>
              <button className=" -mt-14 xl:mt-5 md:mt-5 mr-4 py-3 px-4 rounded-md bg-[#7366FF] hover:bg-[#3f3892] font-semibold flex text-white" onClick={props.import}>
                <Image src='/icons/upload.svg' width={23} height={23} alt={'import'} />
                &nbsp; Import
              </button>
            </>) : (<></>)}
            <button className=" py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={e => DownloadExcel(props.download)}>
              {loader_download ? (<>
                <Spin />
                &nbsp; {download_file ? 'Proses Download' : 'Pembaruan data'}
              </>) : (<>
                {/* <Image src="/icons/update.png" alt="unduh" width={20} height={20} className="mr-2" /> */}
                &nbsp;  {download_file ? <>
                  <Image src="/icons/download.svg" alt="unduh" width={20} height={20} className="mr-2" /> <span className='ml-2'>Download</span>
                </> : <>
                <Image src="/icons/update.png" alt="unduh" width={20} height={20} className="mr-2" /> <span className='ml-2'>Perbarui data</span>
                </>}
              </>)}
            </button>
          </div>
        </div>
      </>) : (<></>)}
      </div>
      <div className="overflow-x-auto mt-3">
        <Table
          columns={heading}
          dataSource={dataSource}
          loading={loading}
          size={'large'}
          pagination={
            props.Hpagination ? false :
            {
              pageSize: paging ? paging : 10,
              position : ['bottomRight'] 
            }
          }
          scroll={{ x: props.Tscroll ? props.Tscroll :  '100vw'}}
          tableLayout={{ width: "100vw" }}
           footer={Tfooter ? () => {
            return <table>
                      { Tfooter.dalam_kota ? <tbody>
                      <tr>
                        <td><strong>Total Volume Kendaraan</strong></td>
                        <td>:</td>
                        <td>{new Intl.NumberFormat('de-DE').format(Tfooter.vol_keluar ? Tfooter.vol_keluar : 0 )} Qemp</td>
                      </tr>
                      </tbody> : <tbody>
                      <tr>
                        <td><strong>Total Volume Masuk</strong></td>
                        <td>:</td>
                        <td>{new Intl.NumberFormat('de-DE').format(Tfooter.vol_masuk ? Tfooter.vol_masuk : 0 )} Qemp</td>
                      </tr>
                      <tr>
                        <td><strong>Total Volume Keluar</strong></td>
                        <td> : </td><td>{new Intl.NumberFormat('de-DE').format(Tfooter.vol_keluar ? Tfooter.vol_keluar : 0)} Qemp</td>
                      </tr>
                      </tbody>}
                    </table>
          }: false }
          bordered
        />
      </div>
    </>
  )
}
export default DataTable
  